import Freeze from '../utilities/Freeze';

export default Freeze({
  MESSAGES_EVENTS: {
    showError: 'message:showError',
    showSuccess: 'message:showSuccess'
  },
  MODAL_EVENTS: {
    show: 'modal:show',
    close: 'modal:close',
    afterOpen: 'modal:afterOpen',
    afterClose: 'modal:afterClose',
    beforeClose: 'modal:beforeClose',
    serviceReady: 'modal:service:ready'
  },
  ALERTS_EVENTS: {
    removed: 'alert:removed'
  },
  LOADER_EVENTS: {
    show: 'loader:show',
    hide: 'loader:hide'
  },
  ADD_TO_WISHLIST_EVENTS: {
    modified: 'wishlist:modified',
    add: 'wish:add:product'
  },
  DROPDOWN_EVENTS: {
    show: 'dropdown:open',
    hide: 'dropdown:close'
  },
  CALENDAR_MODAL_EVENTS: {
    rangeSetted: 'range:setted',
    durationChanged: 'duration:changed'
  },
  RENT_CALENDAR_EVENTS: {
    rangeSetted: 'rent:range:setted'
  },
  RENT_DURATION_EVENTS: {
    changed: 'rent:duration:changed',
    get: 'rent:duration:get'
  },
  SELECT_SIZE_EVENTS: {
    selected: 'selectsize:selected',
    selectedWishlist: 'selectsize:selected:wishlist'
  },
  ADDTOCART_EVENTS: {
    waitForMe: 'addtocart:wait',
    itemAdded: 'cart:item:added'
  },
  PLAYER_EVENTS: {
    ready: 'ready',
    progress: 'progress',
    playing: 'playing',
    play: 'play',
    pause: 'pause',
    timeupdate: 'timeupdate',
    volumechange: 'volumechange',
    seeking: 'seeking',
    seeked: 'seeked',
    ratechange: 'ratechange',
    ended: 'ended',
    enterfullscreen: 'enterfullscreen',
    exitfullscreen: 'exitfullscreen',
    captionsenabled: 'captionsenabled',
    captionsdisabled: 'captionsdisabled',
    languagechange: 'languagechange',
    controlshidden: 'controlshidden',
    controlsshown: 'controlsshown'
  },
  EDIT_ADDRESS_EVENTS: {
    edit: 'editaddress:edit'
  },
  ZOOM_GALLERY_EVENTS: {
    imageChanged: 'image:changed',
    startImage: 'image:start'
  },
  COUNTRY_SELECTOR_EVENTS: {
    change: 'country:selector:value:change'
  },
  FILTERS_EVENTS: {
    doSearch: 'filters:do:search'
  },
  PRODUCT_SEARCH_EVENTS: {
    searchDone: 'product:search:done'
  },
  SORTING_RULES_EVENTS: {
    changed: 'sortingRiles:changed'
  },
  REFINEMENTS_SELECTED_EVENTS: {
    removeFilter: 'refinementsselected:remove:filters'
  },
  INPAGE_LAYER_EVENTS: {
    close: 'inpage:layer:close'
  }
});
