import 'what-input';
import './js/utilities/PublicPath';
import './js/utilities/BrowserDetection';
import './js/utilities/SvgSprite';
import './js/libs/StickyFill';

import { ResolutionHandler } from './js/utilities/ResolutionHandler';
import FormValidator from './js/utilities/FormValidator';

import ComponentDispatcher from './js/utilities/ComponentDispatcher';
import ModalService from './js/services/ModalService';

import './scss/main.scss';

// sta qui perchè altrimenti i css delle dipenzenze vengono importati prima e risulta difficile sovrascriverli

const compDispatcher = new ComponentDispatcher();
const formValidator = new FormValidator();
const modalService = new ModalService();

formValidator.start();
ResolutionHandler();

import(/* webpackChunkName: `service-loader` */ './js/services/LoaderService.js').then((res) => {
  const LoaderService = res.default;
  window._loader_service = new LoaderService();
});

compDispatcher.createAsyncComponents().then((res) => {
  compDispatcher.observeDomChanges();
});

// compDispatcher.createComponents({
//   AccordionComponent,
//   TabsComponent,
//   CarouselComponent,
//   DataToggleComponent,
//   MaterialInputComponent,
//   NewsLetterComponent,
//   NewsletterUnsubscribeComponent,
//   ModalComponent,
//   LoaderComponent,
//   ContatcFormComponent,
//   ChangeLanguageComponent,
//   BurgerComponent,
//   DropdownComponent,
//   ScrollDirectionComponent,
//   DataLayerComponent,
//   InstagramLiveFeedComponent,
//   CookieDisclaimerComponent,
//   DataLinkComponent,
//   RentalCalendarComponent,
//   RentalDurationComponent,
//   RentalConfigsComponent,
//   ModalTriggerComponent,
//   SelectSizeComponent
// });
